import NextImage from 'next/image'

import imageUrl from './404 Error.svg?url'
import { Section } from '../components/section'
import classes from './error.module.css'
import { Heading } from '../components/heading'
import { LinkButton } from '../components/links'
import { routes } from '../utils/routes'

export default function Custom404() {
  return (
    <Section className={classes.section}>
      <NextImage
        src={imageUrl}
        alt="404 error"
        width={750}
        height={500}
        className={classes.imageContainer}
      />
      <Heading theme="h1" className={classes.title}>
        Sorry, Page Not Found
      </Heading>
      <LinkButton theme="secondary" className={classes.link} href={routes.homePage}>
        Home Page
      </LinkButton>
      <a href="https://storyset.com/web" hidden>
        Web illustrations by Storyset
      </a>
    </Section>
  )
}
